














/* tslint:disable:no-console */
  import { Component, Vue, Watch } from "vue-property-decorator";
  import IndexTemplate from "@/components/Index-template.vue";
  import updateTdk from "@/utils/tdk";
  import { blockMap } from "@/configs";

  // @Component 修饰符注明了此类为一个 Vue 组件
  @Component({
    name: "LiveAppInstall",
    components: { IndexTemplate }
  })
  export default class LiveAppInstall extends Vue {
    // 初始数据可以直接声明为实例的属性
    private isMounted: boolean = false;
    private lists: any[] = [];
    private keyWordArr: any[] = [];
    private keyWord: string = "";
    private titleArr: string[] = ['轮播', '推荐合集', '推荐合集', '推荐合集', '推荐合集'];
    private keyId = 0;
    private tdk(self: any) {
      return {
        title: ""
      };
    }
    private serverPrefetch() {
      this.$store.commit("clearPageData"); // 清空全部，重要
      return this.fetchData();
    }
    get indexList(): any[] {
      return this.$store.state.pageDatas.liveAppData || [];
    }
    private fetchData() {
      const self: any = this;
      // 获取首页数据
      self.$doMotion(self, "LOADING_SWITCH", true);
      const cookies = this.$ssrContext && this.$ssrContext.cookies;
      return this.$store
        .dispatch("fetchLiveAppData", { params: { start: 0, max: 50 , cid: self.$route.query.cid } })
        .then(res => {
          const liveAppData = res.value;
          if (liveAppData) {
            // 添加数据来源
            const blocks = blockMap.liveAppInstall
            const res_block = []
            for (let i = 0; i < blocks.length; i++) {
              const block = blocks[i];
              const appList = liveAppData[i].appList;
              const data = []
              for (let j = 0; j < appList.length; j++) {
                const app = appList[j];
                if (i == 0) {
                  // 轮播
                  data.push({
                    img_url: app.imageUrl,
                    downloadUrl: app.downloadUrl,
                    downloadName: app.name,
                  })
                } else {
                  // app列表
                  data.push({
                    downloadUrl: app.downloadUrl, // 以前是通过查询
                    icon: app.icon,
                    download_count: app.downloadCount,
                    name: app.name,
                    recommend_desc: app.recommendDesc,
                    size: app.size,
                  })
                }
              }
              res_block.push({
                name: this.titleArr[i],
                type: block.type,
                data_source : "liveAppInstall",
                data
              })
            }
            console.log(res_block);
            self.$store.commit("setLiveAppData", res_block);
          }
        })
        .catch((err: any) => {
          console.log(err);
          self.$toast.show(err.message || "服务器错误，请刷新！");
        })
        .finally(() => {
          self.$doMotion(self, "LOADING_SWITCH", false);
          updateTdk(self);
        });
    }
    private mounted(): void {
      const self: any = this;
    //   window.testObj={
    //   vm:this
    // }
      self.isMounted = true;
      if (!self.indexList.length) {
        // 是否有值
        self.fetchData();
      }
      updateTdk(self);
    }
    private destroyed() {
      this.$store.commit("liveAppData", []); // 修改store
    }
  }
